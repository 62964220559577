import { connect, ConnectedProps } from "react-redux"
import { MarketplaceEntity } from "../../../../entities/MarketplaceEntity"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (
  state: RootState,
  props: MarketplaceEntity & {
    alreadyBougth?: boolean
  }
) => ({
  ...props,
  alreadyBougth: state.marketplace.items.has(props.itemId),
  bougthAt: state.marketplace.items.get(props.itemId)?.bougthAt || null,
  fetching: state.marketplace.itemFetching === props.itemId,
})

const mapDispatch = (
  dispatch: any,
  props: MarketplaceEntity & {
    alreadyBougth?: boolean
  }
) => ({
  onBuy: () => {
    dispatch(actions.marketplace.$buy({ itemId: props.itemId }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
