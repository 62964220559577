import { ArrowPathIcon, ClockIcon } from "@heroicons/react/20/solid"
import { FireIcon } from "@heroicons/react/24/solid"
import React from "react"
import { MarketplaceEntity } from "../../../entities/MarketplaceEntity"
import {
  connector,
  ContainerProps,
} from "./containers/MarketplaceItem.containers"
import dayjs from "dayjs"
import { getTimeout } from "../../../utils/date"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"
import { useIntl } from "react-intl"

type Props = MarketplaceEntity & {
  alreadyBougth?: boolean
  bougthAt: Date | null
  onBuy: () => void
  fetching: boolean
}

const isCanBeBought = (props: Props): boolean => {
  if (!props.alreadyBougth) return true
  if (
    props.recurrence &&
    dayjs(props.bougthAt).add(1, "days").isBefore(new Date())
  )
    return true
  return false
}

const StockLabel: React.FC<Props> = (props) => {
  if (!props.alreadyBougth) {
    return (
      <div className="font-display py-2 text-center md:text-right md:px-2 text-slate-500 font-semibold">
        <FormattedMessage id="marketplace/stock" />
      </div>
    )
  }

  if (props.alreadyBougth && !props.recurrence) {
    return (
      <div className="font-display py-2 text-center md:text-right md:px-2 text-slate-500 font-semibold">
        <FormattedMessage id="marketplace/no-stock" />
      </div>
    )
  }

  if (
    props.bougthAt &&
    dayjs(props.bougthAt).add(1, "days").isAfter(new Date())
  ) {
    return (
      <div className="font-display py-2 justify-center md:text-right md:px-2 flex items-center md:justify-end text-slate-500 font-semibold">
        <ClockIcon className="h-5 w-5 mr-1" />
        {getTimeout(dayjs(props.bougthAt).add(1, "days").toDate())}
      </div>
    )
  }

  return (
    <div className="font-display py-2 text-center md:text-right md:px-2 text-slate-500 font-semibold">
      <FormattedMessage id="marketplace/stock" />
    </div>
  )
}

const BuyButton: React.FC<Props> = (props) => {
  const intl = useIntl()

  if (props.fetching) {
    return (
      <button className="shadow-btn shadow-slate-200 transition-all ease-in-out duration-150 p-3 px-4 w-full md:w-auto text-center justify-center font-semibold rounded hover:bg-emerald-50 bg-white border border-slate-200 flex items-center font-display">
        <ArrowPathIcon className="h-6 w-6 animate-spin" />{" "}
        <FormattedMessage id="marketplace/buy/loading" />
      </button>
    )
  }

  if (isCanBeBought(props) && props.price === 0) {
    return (
      <button
        onClick={props.onBuy}
        className="shadow-btn shadow-slate-200 transition-all ease-in-out duration-150 p-3 px-4 w-full md:w-auto text-center justify-center font-semibold rounded hover:bg-emerald-50 bg-white border border-slate-200 flex items-center font-display"
      >
        <FormattedMessage id="marketplace/buy-free/button" />
      </button>
    )
  }

  if (isCanBeBought(props) && props.price > 0) {
    return (
      <button
        onClick={props.onBuy}
        className="shadow-btn shadow-slate-200 transition-all ease-in-out duration-150 p-3 px-4 w-full md:w-auto text-center justify-center font-semibold rounded hover:bg-emerald-50 bg-white border border-slate-200 flex items-center font-display"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(
            {
              id: "marketplace/buy/button",
            },
            {
              e: `<span class="text-emerald-500 ml-1">${props.price}</span><img class="h-6 ml-1" src="/emerald.svg" />`,
            }
          ),
        }}
      ></button>
    )
  }

  return (
    <button className="bg-slate-100 cursor-not-allowed text-slate-500 p-3 md:ml-auto px-4 w-full md:w-auto text-center justify-center font-semibold rounded border border-slate-100 flex items-center font-display">
      <FormattedMessage id="marketplace/already-bougth/button" />
    </button>
  )
}

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <div className="border md:flex p-4 bg-white border-slate-200 w-full rounded items-center relative">
      {props.special && (
        <div className="absolute right-0 top-0 p-1">
          <div className="relative">
            <div className="absolute h-3 w-3 inset-0 m-auto rounded-full bg-orange-300" />
            <FireIcon className="w-6 h-6 relative text-orange-500" />
          </div>
        </div>
      )}
      <div className="rounded flex-shrink-0">
        <img
          width={"96px"}
          height={"96px"}
          className="w-24 h-24 mx-auto rounded"
          src={props.image}
        />
      </div>
      <div className="md:px-4 text-center md:text-left mt-2 md:mt-0">
        <p className="font-display font-semibold text-xl">
          <FormattedMessage id={props.title} />
        </p>
        <p className=" text-slate-700">
          <FormattedMessage id={props.description} />
        </p>
      </div>
      <div className="flex-shrink-0 ml-auto">
        <StockLabel {...props} />
        <BuyButton {...props} />
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const MarketplaceItem = connector(Container)
