import React from "react"
import { navigate, PageProps } from "gatsby"
import { useIntl } from "react-intl"
import { useLocation } from "@reach/router"
import { Seo } from "../components/Seo/Seo"
import { Footer } from "../components/Footer/Footer"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { Drawer } from "../components/Drawer/Drawer"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { Navbar } from "../components/Navbar/Navbar"
import { BackgroundShapes } from "../components/BackgroundShapes"
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar"
import { MarketplaceItem } from "../components/Marketplace/components/MarketplaceItem"
import clsx from "clsx"
import { MarketplaceEntity } from "../entities/MarketplaceEntity"
import {
  marketplaceCoinsItems,
  marketplaceLeopardAstronautAvatarItems,
  marketplaceMajesticDogsAvatarItems,
  marketplacePowerUpItems,
  marketplaceRecurrentItems,
  marketplaceWarriorsAvatarItems,
} from "../database/marketplace-items"
import { FormattedMessage } from "../components/FormattedMessage/FormattedMessage"
import { ITranslations } from "../interfaces/ITranslations"
import { MarketplaceBuyCoins } from "../components/Marketplace/components/MarketplaceBuyCoins"
import { Link } from "../components/Link/Link"
import { MarketplaceSubscribeCTA } from "../components/MarketplaceSubscribeCTA/MarketplaceSubscribeCTA"

type Props = PageProps<
  {},
  {
    langKey: string
    level: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const Section: React.FC<{
  title: ITranslations["keys"]
  description: ITranslations["keys"]
  items: MarketplaceEntity[]
}> = (props) => (
  <div className="mt-10">
    <div className="font-display px-4  font-semibold text-2xl">
      <FormattedMessage id={props.title} />
    </div>
    <div className="px-4">
      <FormattedMessage id={props.description} />
    </div>
    <div className="flex flex-nowrap md:block overflow-auto px-4  no-scroll-bar mt-2">
      {props.items.map((item) => (
        <div
          className={clsx(
            props.items.length > 1 &&
              "flex-shrink-0 w-10/12 mr-4 md:mr-0 md:w-full md:mt-2"
          )}
          key={item.itemId}
        >
          <MarketplaceItem {...item} />
        </div>
      ))}
    </div>
  </div>
)

const MarketplacePage: React.FC<Props> = (props) => {
  const intl = useIntl()
  const location = useLocation()

  return (
    <GlobalWrapper>
      <Seo
        title={intl.formatMessage({
          id: `marketplace/title`,
        })}
        description={intl.formatMessage({
          id: `marketplace/description`,
        })}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
        index={false}
        paddle
      />

      <SelectLanguageModal languages={props.pageContext.canonicals} />

      <div className="relative min-h-screen overflow-hidden">
        <BackgroundShapes step={1} absolute />

        <div className={`transition-opacity duration-300 ease-in-out`}>
          <Navbar langKey={props.pageContext.langKey} />
        </div>

        <section className="py-8 md:py-14 relative">
          <div className="container mx-auto">
            <div className="md:max-w-3xl mx-auto mb-4 ">
              <h1 className="mb-4 text-3xl px-4  md:text-5xl text-center font-display leading-tight text-slate-900 font-bold tracking-tighter">
                <FormattedMessage id="marketplace/title" />
              </h1>

              <p className="mb-4 text-lg px-4  md:text-xl text-center text-slate-900">
                <FormattedMessage id="marketplace/description" />
              </p>

              <div className="w-full border-t border-slate-200 mt-8"></div>

              <MarketplaceSubscribeCTA />

              {marketplaceRecurrentItems.length > 0 && (
                <Section
                  title="marketplace/section/recurrent/title"
                  description="marketplace/section/recurrent/description"
                  items={marketplaceRecurrentItems}
                />
              )}

              {/* <Section
                title="marketplace/section/coins/title"
                description="marketplace/section/coins/description"
                items={[]}
              />

              <div className="flex flex-nowrap overflow-auto px-4 no-scroll-bar mt-2">
                {marketplaceCoinsItems.map((item) => (
                  <div
                    key={item.amount}
                    className={clsx(
                      "flex-shrink-0 w-10/12 md:flex-shrink mr-4 last:mr-0 md:w-full md:mt-2"
                    )}
                  >
                    <MarketplaceBuyCoins {...item} />
                  </div>
                ))}
              </div> */}

              {marketplacePowerUpItems.length > 0 && (
                <Section
                  title="marketplace/section/recurrent/title"
                  description="marketplace/section/recurrent/description"
                  items={marketplacePowerUpItems}
                />
              )}

              {marketplaceWarriorsAvatarItems.length > 0 && (
                <Section
                  title="marketplace/section/avatar/title"
                  description="marketplace/section/avatar/description"
                  items={marketplaceWarriorsAvatarItems}
                />
              )}

              {marketplaceLeopardAstronautAvatarItems.length > 0 && (
                <Section
                  title="marketplace/section/avatar/title"
                  description="marketplace/section/avatar/description"
                  items={marketplaceLeopardAstronautAvatarItems}
                />
              )}

              {marketplaceMajesticDogsAvatarItems.length > 0 && (
                <Section
                  title="marketplace/section/avatar/title"
                  description="marketplace/section/avatar/description"
                  items={marketplaceMajesticDogsAvatarItems}
                />
              )}
            </div>
          </div>
        </section>
      </div>

      <MobileNavbar langKey={props.pageContext.langKey} />
      <Footer lang={props.pageContext.langKey} />
      <Drawer langKey={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default MarketplacePage
